<template>
    <div>
        <nav-bar title="推广码" url="/sale"></nav-bar>
        <!--用户信息-->
        <div class="top">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-field label-width="70" label-class="label" v-model="data.sale_name" label="推广名称"
                    placeholder="请输入推广名称"></van-field>
                <van-field label-width="70" label-class="label" v-model="data.full_name" label="真实姓名"
                    placeholder="请输入真实姓名"></van-field>
                <van-field label-width="70" label-class="label" v-model="data.sale_tel" label="联系电话"
                    placeholder="请输入联系电话"></van-field>

                <!-- 部门列表-->
                <van-field label-width="70" label-class="label" v-model="data.bumen_name"
                    v-if="type==1  && !info.bumen_name" label="所属部门" placeholder="点击选择所属部门" @click="showPicker = true">
                </van-field>

                <van-field label-width="70" label-class="label" v-if="info.bumen_name" v-model="data.bumen_name"
                    disabled label="所属部门" placeholder="点击选择所属部门"></van-field>

                <van-popup v-model:show="showPicker" position="bottom">
                    <van-picker title="部门" show-toolbar :columns="bumen" @confirm="onConfirm"
                        @cancel="showPicker = false"></van-picker>
                </van-popup>

                <div class="hb">
                    <van-row gutter="10">
                        <van-col span="8" v-for="(item,index) in hb" :key="index">
                           <div class="hb-list" :class="hb_type==item.id?'active':''">
                                <van-image width="100%" height="160" fit="cover" :src="item.url + '!w200'"
                                    @click="hb_type = item.id"></van-image>
                                <div class="hb-name">{{item.name}}</div>
                           </div>
                        </van-col>
                    </van-row>
                </div>
            </van-cell-group>
        </div>

        <div class="action">
            <van-button type="primary" @click="submit" :color="config.main_color" size="large" round>生成二维码</van-button>
        </div>

        <!--二维码-->
        <div class="qr">
            <van-image style="border: 1px solid var(--main-color);" width="300px" height="60vh" fit="cover"
                :src="info.qr" @click="preview(info.qr,0)"></van-image>
            <div class="msg">点击查看原图，长按保存到相册</div>
        </div>
    </div>
</template>

<script>
    import { oauth } from "@/static/js/oauth";
    import {
        sale_info,
        sale_qr,
    } from '@/api/sale.js';
    import {
        Toast,
        Dialog,
        ImagePreview,
    } from 'vant';
    export default {
        name: 'sale_qr',
        data() {
            return {
                showPicker: false,
                info: {},
                cate: [],
                hb: [],
                bumen: [],
                data: {
                    cate_id: '0',
                    sale_name:'',
                    full_name:'',
                    sale_tel:'',
                },
                type: 0,
                hb_type: 0,
            }
        },
        created() {
            oauth();
            this.type = this.$route.query.type;
        },
        mounted() {
            this.get_sale();
        },
        methods: {
            get_sale() {
                sale_info({}).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        this.data.sale_name = res.data.sale_name;
                        this.data.full_name = res.data.full_name;
                        this.data.full_name2 = res.data.full_name;
                        this.data.sale_tel = res.data.sale_tel;
                        this.data.bumen_name = res.data.bumen_name;
                        this.data.cate_id = '0';
                        this.hb = res.data.hb;
                        this.hb_type = res.data.hb[0].id;
                        this.bumen = res.data.bumen;
                    }
                });
            },


            submit() {
                var that = this;
                if (!this.data.sale_name) {
                    Toast('推广名称不得为空！');
                    return false;
                }
                sale_qr({
                    id: this.info.id,
                    sale_name: this.data.sale_name,
                    full_name: this.data.full_name,
                    sale_tel: this.data.sale_tel,
                    hb_type: this.hb_type,
                    cate_id: this.data.cate_id,
                    bumen_name: this.data.bumen_name,
                    loading: true,
                    loading_text: '生成中……',
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        this.preview(res.data.qr, 0);
                    }
                });
            },


            //图片预览
            preview(img, index) {
                ImagePreview({
                    images: [img],
                    startPosition: index ? index : 0,
                    closeable: true,
                });
            },

            onConfirm(value) {
                this.data.bumen_name = value;
                this.showPicker = false;
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .qr {
        text-align: center;
        background: #fff;
        margin: 20px;
        padding: 15px;
        width: calc(100% - 70px);
    }

    .msg {
        text-align: center;
        font-size: 14px;
        color: red;
    }

    /deep/ .van-radio__label {
        width: 200px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .main-color {
        color: var(--main-color);
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    .top {
        width: calc(100% - 40px);
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }
    .hb{
        padding: 15px;
        width: calc(100% - 30px);
    }
    .hb-list{
        margin-bottom: 15px;
        border: 1px solid #fff;
    }
    .hb-name{
        font-size: 12px;
        height: 18px;
        overflow: hidden;
        text-align: center;
    }
    .active{
        border: 1px solid var(--main-color);
    }
</style>